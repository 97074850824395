.ftco-wrap-about {
  background-position: bottom center !important;
}
.ftco-wrap-about .heading-section h2 {
  font-weight: 600 !important;
}
.ftco-wrap-about .img {
  width: 100%;
}
@media (min-width: 992px) {
  .ftco-wrap-about .img-1 {
    margin-top: -30px;
  }
}
@media (min-width: 992px) {
  .ftco-wrap-about .img-2 {
    margin-bottom: -30px;
  }
}

.wrap-about {
  position: relative;
}
.wrap-about .time span {
  display: block;
}
.wrap-about .time span a {
  font-size: 40px;
  font-weight: 700;
}
@media (max-width: 767.98px) {
  .wrap-about .time span a {
    font-size: 30px;
  }
}
