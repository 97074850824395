.p-relative {
  position: relative;
}

.z-index {
  position: relative;
  z-index: 1;
}

.header-sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  visibility: visible;
  width: 100%;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  animation: 0.5s ease-in-out 0s normal none 1 running fadeInDown;
  z-index: 999;
}

.header-sticky .tp-header-main__menu-bg {
  width: 100%;
}

.tp-header-top__plr {
  padding-left: 100px;
  padding-right: 100px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tp-header-top__plr {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.tp-header-top__height {
  min-height: 45px;
  line-height: 45px;
}
.tp-header-top__left-info ul li {
  display: inline-block;
  list-style-type: none;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.02em;
  color: #2a2222;
  padding-right: 25px;
  margin-right: 25px;
  position: relative;
}
.tp-header-top__left-info ul li:first-child a {
  text-decoration: underline;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px),
  only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-header-top__left-info ul li:nth-child(2) {
    display: none;
  }
  .tp-header-top__left-info ul li:nth-child(3) {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-header-top__left-info ul li {
    margin-right: 0;
  }
  .tp-header-top__left-info ul li:nth-child(2) {
    display: none;
  }
  .tp-header-top__left-info ul li:nth-child(3) {
    display: none;
  }
}
.tp-header-top__left-info ul li:last-child {
  padding-right: 0;
  margin-right: 0;
}
.tp-header-top__left-info ul li:last-child::after {
  display: none;
}
.tp-header-top__left-info ul li svg {
  margin-top: -2px;
  margin-right: 4px;
}
.tp-header-top__left-info ul li::after {
  content: "";
  height: 20px;
  width: 1px;
  right: 0;
  top: 13px;
  position: absolute;
  background-color: rgba(104, 94, 94, 0.29);
}
@media only screen and (min-width: 1400px) and (max-width: 1599px),
  only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-header-top__left-info ul li::after {
    display: none;
  }
}
.tp-header-top__left-info ul li span {
  height: 22px;
  line-height: 22px;
  padding: 0px 13px;
  background-color: #ffb302;
  color: #fff;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 0.15em;
  margin-right: 13px;
  transform: translateY(-2px);
}
.tp-header-top__left-info ul li a:hover {
  color: #336ef9;
}
.tp-header-top__value {
  position: relative;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-header-top__value {
    margin-left: 20px;
  }
}
.tp-header-top__value ul li {
  list-style-type: none;
}
.tp-header-top__value ul li a {
  font-weight: 400;
  font-size: 14px;
  color: #2a2222;
}
.tp-header-top__value ul li a img {
  width: 25px;
  height: 25px;
  border: 3px solid #fff;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.13);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  margin-right: 10px;
}
.tp-header-top__value ul li a span {
  margin-left: 5px;
}
.tp-header-top__value ul li a span svg {
  transform: translateY(-1px);
}
.tp-header-top__value ul li ul.tp-header-top__value-submenu {
  position: absolute;
  top: 130%;
  left: 0px;
  width: 80px;
  background: #fff;
  z-index: 99;
  box-shadow: 0 30px 70px 6px rgba(11, 6, 70, 0.08);
  padding: 15px 20px;
  border-radius: 4px;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
  visibility: hidden;
}
.tp-header-top__value ul li ul.tp-header-top__value-submenu.open {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.tp-header-top__value ul li ul.tp-header-top__value-submenu li {
  line-height: 1;
  margin-bottom: 10px;
  list-style-type: none;
}
.tp-header-top__value ul li ul.tp-header-top__value-submenu li:last-child {
  margin-bottom: 0;
}
.tp-header-top__value ul li ul.tp-header-top__value-submenu li a:hover {
  color: #336ef9;
}
.tp-header-top__lang {
  position: relative;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-header-top__lang {
    margin-left: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-header-top__lang {
    margin-right: 20px;
  }
}
.tp-header-top__lang > ul > li {
  list-style-type: none;
}
.tp-header-top__lang > ul > li a {
  font-weight: 400;
  font-size: 15px;
  color: #2a2222;
}
.tp-header-top__lang > ul > li a img {
  width: 25px;
  height: 25px;
  border: 3px solid #fff;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.13);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  margin-right: 10px;
}
.tp-header-top__lang > ul > li a span {
  margin-left: 5px;
}
.tp-header-top__lang > ul > li ul.tp-header-top__lang-submenu {
  position: absolute;
  top: 130%;
  left: 0px;
  width: 110px;
  background: #fff;
  z-index: 9;
  box-shadow: 0 30px 70px 6px rgba(11, 6, 70, 0.08);
  padding: 15px 20px;
  border-radius: 4px;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
  visibility: hidden;
}
.tp-header-top__lang > ul > li ul.tp-header-top__lang-submenu.open {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.tp-header-top__lang > ul > li ul.tp-header-top__lang-submenu li {
  line-height: 1;
  margin-bottom: 10px;
  list-style-type: none;
}
.tp-header-top__lang > ul > li ul.tp-header-top__lang-submenu li:last-child {
  margin-bottom: 0;
}
.tp-header-top__lang > ul > li ul.tp-header-top__lang-submenu li a:hover {
  color: #336ef9;
}
.tp-header-top__social {
  background-color: #336ef9;
  padding: 0px 17px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-header-top__social {
    padding: 0 14px;
  }
}
.tp-header-top__social ul li {
  display: inline-block;
  list-style-type: none;
}
.tp-header-top__social ul li a {
  color: #fff;
  font-size: 18px;
  margin: 0px 10px;
}
.tp-header-top__social ul li a:hover {
  color: #ffb302;
}

.tp-header-main__menu-wrapper {
  display: flex;
  justify-content: flex-end;
}

.tp-header-main__menu-box {
  margin-right: 134px;
}
@media only screen and (min-width: 1700px) and (max-width: 1800px) {
  .tp-header-main__menu-box {
    margin-right: 60px;
  }
}
@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .tp-header-main__menu-box {
    margin-right: 80px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-header-main__menu-box {
    margin-right: 50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-header-main__menu-box {
    margin-right: 0px;
  }
}

.tp-header-main__pl {
  padding-left: 100px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tp-header-main__pl {
    padding-left: 15px;
  }
}
.tp-header-main__menu-bg {
  background: linear-gradient(90deg, rgba(11, 186, 251, 1) 0%, rgba(66, 133, 236, 1) 100%);
  -webkit-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  padding-left: 45px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-header-main__menu-bg {
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .tp-header-main__menu-bg {
    padding-left: 0;
  }
}
.tp-header-main__logo-wrap {
  position: absolute;
  top: 0;
  left: 115px;
}
.tp-header-main__logo span {
  position: absolute;
  top: 0%;
  left: 0%;
}
.tp-header-main__logo-1 {
  position: relative;
  top: 20px;
  left: 30px;
}
.tp-header-main__menu > nav > ul {
  text-align: end;
  margin: 0;
}
.tp-header-main__menu > nav > ul > li {
  display: inline-block;
  list-style-type: none;
  margin: 0px 35px;
  position: relative;
}
@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .tp-header-main__menu > nav > ul > li {
    margin: 0px 25px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-header-main__menu > nav > ul > li {
    margin: 0px 19px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-header-main__menu > nav > ul > li {
    margin: 0px 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-header-main__menu > nav > ul > li {
    margin: 0px 15px;
  }
}
.tp-header-main__menu > nav > ul > li:last-child {
  margin-right: 0;
}
.tp-header-main__menu > nav > ul > li.has-dropdown-2::after {
  content: "\f063";
  font-family: "Font Awesome 6 Pro";
  display: inline-block;
  color: #ffb302;
  font-size: 12px;
  font-weight: 700;
  transition: 0.3s;
  transform: translateY(-1px);
  margin-left: 15px;
}
.tp-header-main__menu > nav > ul > li:nth-child(2) {
  margin-right: 40px;
  margin-left: 15px;
}
@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .tp-header-main__menu > nav > ul > li:nth-child(2) {
    margin-right: 10px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-header-main__menu > nav > ul > li:nth-child(2) {
    margin-right: 10px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-header-main__menu > nav > ul > li:nth-child(2) {
    margin-right: 12px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-header-main__menu > nav > ul > li:nth-child(2) {
    margin-right: 15px;
  }
}
.tp-header-main__menu > nav > ul > li > .submenu {
  position: absolute;
  background-color: #fff;
  width: 240px;
  z-index: 999;
  padding: 30px 0px;
  top: 110%;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  text-align: left;
  margin-left: 0;
  border-top: 5px solid #336ef9;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.tp-header-main__menu > nav > ul > li > .submenu > li {
  margin: 0;
  display: block;
  margin-bottom: 10px;
  padding: 0 45px;
}
.tp-header-main__menu > nav > ul > li > .submenu > li:last-child {
  margin-bottom: 0;
}
.tp-header-main__menu > nav > ul > li > .submenu > li > a {
  padding: 0;
  margin: 0;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  position: relative;
  letter-spacing: 1px;
  font-weight: 500;
}
.tp-header-main__menu > nav > ul > li > .submenu > li > a:hover {
  color: #d6f4ff;
}
.tp-header-main__menu > nav > ul > li > .submenu > li .submenu {
  left: 100%;
  top: 0;
}
.tp-header-main__menu > nav > ul > li > a {
  font-weight: 500;
  font-size: 19px;
  color: #fff;
  text-align: center;
  padding: 24px 0px;
  display: inline-block;
}
.tp-header-main__menu > nav > ul > li:hover > .submenu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.tp-header-main__menu > nav > ul > li:hover > a {
  color: #d6f4ff;
}
.tp-header-main__right-info ul li {
  list-style-type: none;
  display: inline-block;
  margin-left: 60px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-header-main__right-info ul li {
    margin-left: 25px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-header-main__right-info ul li {
    margin-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-header-main__right-info ul li {
    margin-left: 30px;
  }
}
@media (max-width: 767px) {
  .tp-header-main__right-info ul li {
    margin-left: 0px;
  }
}
.tp-header-main__right-info ul li:first-child {
  margin-left: 0;
}
.tp-header-main__search {
  padding-left: 47px;
  position: relative;
}
.tp-header-main__search::after {
  position: absolute;
  content: "";
  top: 8px;
  left: 0;
  width: 1px;
  height: 40px;
  background-color: #edeeee;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tp-header-main__search::after {
    display: none;
  }
}
.tp-header-main__search button {
  height: 55px;
  width: 55px;
  line-height: 53px;
  border-radius: 50%;
  background-color: #336ef9;
  text-align: center;
  display: inline-block;
  transition: 0.3s;
}
.tp-header-main__search button span {
  margin-left: -2px;
}
.tp-header-main__search:hover button {
  background-color: #ffb302;
}
.tp-header-main__phone-content span {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.53);
  display: block;
}
.tp-header-main__phone-content a {
  font-weight: 500;
  font-size: 16px;
  color: #020626;
  transition: 0.3s;
}
.tp-header-main__phone-content a:hover {
  color: #336ef9;
}
.tp-header-main__bar button {
  height: 100px;
  width: 110px;
  line-height: 100px;
  text-align: center;
  background-color: #336ef9;
  color: #fff;
  font-size: 30px;
}
@media (max-width: 767px) {
  .tp-header-main__bar button {
    height: 80px;
    width: 90px;
    line-height: 88px;
  }
}
.tp-header-main__phone-icon {
  margin-right: 15px;
}

.tp-header-xl__bar button {
  height: 55px;
  width: 55px;
  line-height: 53px;
  background-color: #336ef9;
  text-align: center;
  display: inline-block;
  color: #fff;
  font-size: 24px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-header-xl__bar button {
    margin-left: 30px;
  }
}
.tp-header-xl__mob-space {
  padding: 15px 0;
}

.header-icon svg {
  transform: translateY(-2px);
}

tp-choose__bg {
  padding-top: 165px;
  padding-bottom: 130px;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px),
  only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-choose__bg {
    background-position: center;
  }
}
.tp-choose__shape-1 {
  position: absolute;
  top: 0;
  left: 0;
}
.tp-choose__shape-2 {
  position: absolute;
  bottom: 0;
  left: 0;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-choose__shape-2 {
    left: auto;
    right: 0;
  }
}
.tp-choose__title-box {
  margin-bottom: 40px;
}
.tp-choose__tab-btn {
  margin-bottom: 40px;
}
.tp-choose__tab-btn button {
  height: 45px;
  padding: 0 50px;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;
  color: #020626;
  margin-right: 18px;
  position: relative;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 767px) {
  .tp-choose__tab-btn button {
    padding: 0px 30px;
    margin-bottom: 20px;
  }
}
.tp-choose__tab-btn button:last-child {
  margin-right: 0;
}
.tp-choose__tab-btn button::after {
  position: absolute;
  height: auto;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
  content: "";
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out 0s;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #336ef9;
}
.tp-choose__tab-btn button:hover {
  background-color: #336ef9;
  color: #fff;
}
.tp-choose__tab-btn button:hover::after {
  opacity: 1;
  visibility: visible;
}
.tp-choose__tab-btn button.active {
  background-color: #336ef9;
  color: #fff;
}
.tp-choose__tab-btn button.active::after {
  opacity: 1;
  visibility: visible;
}
.tp-choose__tab-price {
  margin-bottom: 40px;
}
.tp-choose__price-monthly {
  background: #fff;
  border: 1px solid #336ef9;
  border-radius: 5px;
  padding: 30px;
  margin-right: 40px;
}
@media (max-width: 767px) {
  .tp-choose__price-monthly {
    padding: 20px;
    margin-right: 20px;
  }
}
.tp-choose__price-monthly h5 {
  font-weight: 700;
  font-size: 67px;
  color: #336ef9;
  font-family: "Mulish", sans-serif;
}
.tp-choose__price-monthly h5 em {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #020626;
  font-family: "Mulish", sans-serif;
  font-style: normal;
}
.tp-choose__price-installation-fee h5 {
  font-weight: 700;
  font-size: 67px;
  color: #ffb302;
  font-family: "Mulish", sans-serif;
}
.tp-choose__price-installation-fee h5 em {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #ffb302;
  font-family: "Mulish", sans-serif;
  font-style: normal;
}
.tp-choose__tab-feature ul li {
  list-style-type: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: rgba(20, 33, 43, 0.7);
  position: relative;
  padding-left: 30px;
  margin-bottom: 22px;
}
@media (max-width: 767px) {
  .tp-choose__tab-feature ul li {
    font-size: 16px;
  }
}
.tp-choose__tab-feature ul li:last-child {
  margin-bottom: 0;
}
.tp-choose__tab-feature ul li span {
  position: absolute;
  top: -1px;
  left: 0;
}
.tp-choose__right-box {
  margin-left: 105px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-choose__right-box {
    margin-left: 0;
  }
}
.tp-choose__offer-box {
  display: inline-block;
  transform: translateY(-200px);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-choose__offer-box {
    transform: translateY(0);
  }
}
.tp-choose__img-2 {
  margin-left: 20px;
}
.tp-choose__img-3 {
  position: absolute;
  top: 20%;
  right: -15%;
  animation: tpswing 1s ease-in-out 1s forwards infinite alternate;
  transform-origin: top right;
}
.tp-choose__play-btn {
  margin-top: 20px;
}
.tp-choose__play-btn a {
  height: 93px;
  width: 93px;
  background-color: #fff;
  border-radius: 50%;
  line-height: 93px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  position: relative;
}
.tp-choose__play-btn a span {
  margin-left: 5px;
}
.tp-choose__play-btn a::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: rgba(255, 255, 255, 0.28);
  animation: pulse 2s infinite;
  transform: scale(1.2);
  border-radius: 50%;
  z-index: -1;
}

.tp-choose-2__customize .tp-section-title {
  color: #fff;
}
.tp-choose-2__customize .tp-about__text p {
  color: #707481;
}
@media (max-width: 767px) {
  .tp-choose-2__customize .tp-about__text p br {
    display: none;
  }
}
.tp-choose-2__section-title-box {
  margin-bottom: 50px;
}
.tp-choose-2__title-sm {
  font-weight: 700;
  font-size: 18px;
  color: #fff;
}
.tp-choose-2__content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: #707481;
}
.tp-choose-2__icon {
  margin-right: 18px;
}
.tp-choose-2__bg img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 49%;
  object-fit: cover;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-choose-2__bg img {
    position: static;
    width: 100%;
    margin-bottom: 70px;
  }
}
@media (max-width: 767px) {
  .tp-choose-2__bg img {
    position: static;
    width: 100%;
    margin-bottom: 40px;
  }
}
.tp-choose-2__right-wrapper {
  padding-left: 47px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tp-choose-2__right-wrapper {
    padding-left: 0;
    margin-bottom: 70px;
  }
}
.tp-choose-2__bg-inner-content {
  background-color: #336ef9;
  padding: 40px 50px;
  padding-right: 110px;
  position: absolute;
  bottom: 0;
  left: 0;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-choose-2__bg-inner-content {
    padding: 40px 40px;
    padding-right: 70px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-choose-2__bg-inner-content {
    padding: 40px 45px;
    padding-right: 65px;
  }
}
@media (max-width: 767px) {
  .tp-choose-2__bg-inner-content {
    padding-right: 0px;
    padding: 30px;
  }
}
.tp-choose-2__bg-inner-content span {
  font-style: italic;
  font-weight: 500;
  font-size: 26px;
  line-height: 51px;
  color: #fff;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-choose-2__bg-inner-content span {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-choose-2__bg-inner-content span {
    font-size: 19px;
  }
}
@media (max-width: 767px) {
  .tp-choose-2__bg-inner-content span {
    font-size: 22px;
    line-height: 29px;
  }
}
.tp-choose-2__bg-inner-content span em {
  color: #ffb302;
}
.tp-choose-2__mpbs-icon {
  position: absolute;
  top: 20px;
  right: -43px;
}
.tp-choose-2__bottom-shape {
  position: absolute;
  bottom: 40px;
  left: -25px;
  z-index: 1;
}

.tp-choose-3__overlay {
  position: relative;
}
.tp-choose-3__overlay::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(258.98deg, rgba(20, 23, 41, 0.92) 28.78%, rgba(3, 23, 62, 0) 73.15%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.tp-choose-3__content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.7);
  padding-bottom: 20px;
}
.tp-choose-3__content .child-1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #fff;
  display: block;
  padding-bottom: 45px;
}
.tp-choose-3__content .child-2 {
  font-weight: 500;
  font-size: 20px;
  display: inline-block;
  color: #fff;
  padding-bottom: 35px;
}
.tp-choose-3__content .child-2 em {
  font-style: normal;
  color: #ffb302;
  font-weight: 700;
  font-size: 67px;
}

.black-bg-4 {
  background-color: #323848;
}

.landing-slider {
  border: 2px solid #0fb6fa !important;
  /* margin-top: 4rem !important; */
  border-radius: 10px;
  box-shadow: 1px 2px 10px 0px rgba(12, 125, 201, 0.75);
  -webkit-box-shadow: 1px 2px 10px 0px rgba(12, 125, 201, 0.75);
}

.landing-title {
  /* margin-top: -1.5rem; */
  font-family: "Baloo Paaji 2", sans-serif;
  font-weight: 600;
  background: linear-gradient(90deg, rgba(11, 186, 251, 1) 0%, rgba(66, 133, 236, 1) 100%);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.landing-description {
  font-weight: 400;
  font-size: 18px;
  font-family: "Baloo Paaji 2", sans-serif;
}

.landing-license-button {
  width: 100% !important;
  font-weight: 600;
  font-size: 22px !important;
  font-family: "Baloo Paaji 2", sans-serif;
  background: linear-gradient(90deg, rgba(11, 186, 251, 1) 0%, rgba(66, 133, 236, 1) 100%);
  color: white;
}

.landing-call-button {
  font-weight: 600;
  font-size: 22px !important;
  font-family: "Baloo Paaji 2", sans-serif;
}

@media (max-width: 767px) {
  .landing-license-button {
    font-size: 18px !important;
  }
  .landing-main {
    height: auto;
  }
  .landing-slider {
    margin-top: 0 !important;
  }
  .landing-call-button {
    font-size: 18px !important;
  }
}

@media (max-width: 999px) {
  .landing-license-button {
    font-size: 18px !important;
  }
  .landing-main {
    height: auto;
  }
  .landing-slider {
    margin-top: 0 !important;
  }
  .landing-call-button {
    font-size: 18px !important;
  }
}

@media (max-width: 1200px) {
  .landing-main {
    height: auto;
    margin-top: 100px;
  }
  .landing-logos {
    margin-top: 2.5rem;
  }
  .landing-slider {
    margin-top: 0 !important;
  }
}

@media (max-width: 1450px) {
  .landing-license-button {
    font-size: 17px !important;
  }
  .landing-call-button {
    font-size: 17px !important;
  }
}
