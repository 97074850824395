#login .view {
  background-size: cover;
  height: 100vh;
}

#login .card {
  background-color: rgba(229, 228, 255, 0.2);
}
#login h6 {
  line-height: 1.7;
}

#login .form-check {
  margin-bottom: 0 !important;
}

/*
#login .card {
  background-color: rgba(229, 228, 255, 0.2);
}
#login h6 {
  line-height: 1.7;
}

#login .form-check {
  margin-bottom: 0 !important;
}
#login .md-form label {
  color: #ffffff;
}

#login .md-form input[type="text"],
#login .md-form input[type="password"],
#login .md-form input[type="email"] {
  color: #fff;
}

#login .md-form input[type="text"]:focus:not([readonly]) + label,
#login .md-form input[type="password"]:focus:not([readonly]) + label,
#login .md-form input[type="email"]:focus:not([readonly]) + label {
  color: #fff;
}
#login .md-form input[type="text"]:focus:not([readonly]),
#login .md-form input[type="password"]:focus:not([readonly]),
#login .md-form input[type="email"]:focus:not([readonly]) {
  border-bottom: 1px solid #8edef8;
  box-shadow: 0 1px 0 0 #8edef8;
}


#login .form-control::placeholder {
  color: #fff;
}

#login .md-form.input-group .input-group-text.md-addon {
  color: #fff;
}
*/
