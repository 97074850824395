.lisanslama-timeline {
  padding-top: 90px;
  padding-bottom: 90px;
  background-color: #efefef;
}
.lisanslama-timeline .mbr-section-subtitle {
  color: #767676;
  text-align: center;
  font-weight: 300;
}
.lisanslama-timeline .timeline-text-content {
  padding: 2rem 2.5rem;
  background: #ffffff;
  margin-left: 2rem;
}
.lisanslama-timeline .timeline-text-content p {
  margin-bottom: 0;
}
.lisanslama-timeline .time-line-date-content {
  margin-right: 2rem;
}
.lisanslama-timeline .time-line-date-content p {
  padding: 2rem 2.5rem;
  background: #ffffff;
  float: right;
}
.lisanslama-timeline .timeline-element {
  margin-bottom: 50px;
  position: relative;
  word-wrap: break-word;
  word-break: break-word;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
}
.lisanslama-timeline .timeline-element:hover .mbr-timeline-date {
  box-shadow: 0 7px 20px 0px rgba(0, 0, 0, 0.08);
  transition: all 0.4s;
}
.lisanslama-timeline .timeline-element:hover .timeline-text-content {
  box-shadow: 0 7px 20px 0px rgba(0, 0, 0, 0.08);
  transition: all 0.4s;
}
.lisanslama-timeline .mbr-timeline-date,
.lisanslama-timeline .timeline-text-content {
  transition: all 0.4s;
  border-radius: 12.5px;
}
.lisanslama-timeline .reverse {
  flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
}
.lisanslama-timeline .reverse .timeline-text-content {
  margin-right: 2rem;
  margin-left: 0;
}
.lisanslama-timeline .reverse .time-line-date-content {
  margin-left: 2rem;
  margin-right: 0rem;
}
.lisanslama-timeline .reverse .time-line-date-content p {
  float: left;
}
.lisanslama-timeline .iconBackground {
  position: absolute;
  left: 50%;
  width: 20px;
  height: 20px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  font-size: 30px;
  display: inline-block;
  background-color: #c8a97e;
  top: 20px;
  margin-left: -10px;
}
.lisanslama-timeline .separline:before {
  top: 20px;
  bottom: 0;
  position: absolute;
  content: "";
  width: 2px;
  background-color: #ffffff;
  left: calc(50% - 1px);
  height: calc(100% + 4rem);
}
@media (max-width: 768px) {
  .lisanslama-timeline .iconBackground {
    left: 0 !important;
  }
  .lisanslama-timeline .separline:before {
    left: 0 !important;
  }
  .lisanslama-timeline .timeline-text-content {
    margin-left: 0 !important;
  }
  .lisanslama-timeline .time-line-date-content {
    margin-right: 0 !important;
  }
  .lisanslama-timeline .time-line-date-content p {
    float: left !important;
  }
  .lisanslama-timeline .reverse .time-line-date-content {
    margin-left: 0 !important;
  }
  .lisanslama-timeline .reverse .timeline-text-content {
    margin-right: 0 !important;
  }
}
.lisanslama-timeline .reverseTimeline {
  display: flex;
  flex-direction: column-reverse;
}
