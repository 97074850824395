
/* #section-counter {
  position: relative;
  z-index: -1;
}
#section-counter:after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  z-index: -1;
  opacity: 0;
  background: #000000;
}
 */
.ftco-counter {
  padding-bottom: 5em;
}
@media (max-width: 1199.98px) {
  .ftco-counter {
    background-position: center center !important;
  }
}
.ftco-counter .block-18 {
  display: block;
  width: 100%;
}
@media (max-width: 767.98px) {
  .ftco-counter .block-18 {
    text-align: center;
  }
}
.ftco-counter .text strong.number {
  font-weight: 600;
  font-size: 44px;
  color: #11a0d9;
  display: block;
  line-height: 1;
}
.ftco-counter .text span {
  display: block;
  font-size: 14px;
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .ftco-counter .counter-wrap {
    margin-bottom: 20px;
  }
}
