.services {
  width: 100%;
  display: block;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
}
.services .icon {
  line-height: 1.3;
  position: relative;
  margin: 0 auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.services .icon span {
  font-size: 54px;
  color: #c8a97e;
}
.services .media-body h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 20px;
  color: #000000;
}
