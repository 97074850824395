.site {
  font-family: "Poppins", Arial, sans-serif;
  background: #fff;
  font-size: 17px;
  line-height: 2;
  font-weight: 400;
  color: #666666;
}
.site.menu-show {
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
}
.top-nav {
  background: transparent !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background: rgba(255, 255, 255, 0.05) !important;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
}
@media (max-width: 991.98px) {
  .top-nav {
    display: none;
  }
}

.ftco-animate {
  opacity: 0;
  visibility: hidden;
}

.about-author .desc h3 {
  font-size: 24px;
}

.ftco-section {
  padding: 7em 0;
  position: relative;
}

.ftco-no-pt {
  padding-top: 0;
}

.ftco-no-pb {
  padding-bottom: 0;
}

.ftco-bg-dark {
  background: #3c312e;
}

.bg-light {
  background: #fafafa !important;
}

.bg-white {
  background: #fff !important;
}

.bg-primary {
  background: #11a0d9;
}

@media (max-width: 990px) {
  .featured-wrapper {
    background: #142634;
    color: #fff;
  }
}

.featured {
  padding-top: 30px;
  width: 100%;
}
@media (min-width: 992px) {
  .featured {
    margin-top: -230px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
}
.featured .featured-menus {
  width: 100%;
  margin-bottom: 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.featured .featured-menus .text {
  width: 100%;
}
.featured .featured-menus .text h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .featured .featured-menus .text h3 {
    color: #fff;
  }
}
.featured .featured-menus .text p {
  font-size: 14px;
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .featured .featured-menus .text p {
    color: rgba(255, 255, 255, 0.8);
  }
}
.featured .featured-menus .menu-img {
  width: 90px;
  height: 90px;
  margin: 0 auto;
  margin-bottom: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.menus {
  width: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.menus .text {
  width: 50%;
  padding: 30px 20px;
  border: 1px solid #e6e6e6;
}
@media (max-width: 767.98px) {
  .menus .text {
    width: 100%;
  }
}
.menus .text .one-half {
  width: calc(100% - 80px);
}
.menus .text .one-half h3 {
  font-size: 20px;
  font-weight: 600;
}
.menus .text .one-forth {
  width: 80px;
  text-align: left;
}
.menus .text .one-forth .price {
  font-size: 20px;
  color: #11a0d9;
  font-weight: 600;
  line-height: 1.3;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.menus .text p {
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.8);
}
.menus .menu-img {
  width: 50%;
}
@media (max-width: 767.98px) {
  .menus .menu-img {
    width: 100%;
    height: 250px;
  }
}

.heading-section {
  position: relative;
}
.heading-section .subheading {
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  font-weight: normal;
  font-size: 100px;
  display: block;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.2);
  color: #11a0d9;
  line-height: 1;
  font-family: "Great Vibes", cursive;
  margin-top: 0;
  z-index: -1;
}
@media (max-width: 767.98px) {
  .heading-section .subheading {
    font-size: 90px;
    top: -40px;
  }
}
.heading-section h2 {
  color: #153f59;
  font-size: 50px;
  font-weight: 900;
  line-height: 1.2;
}
@media (max-width: 767.98px) {
  .heading-section h2 {
    font-size: 40px;
  }
}
.heading-section h3 {
  color: #153f59;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
}
@media (max-width: 767.98px) {
  .heading-section h3 {
    font-size: 30px;
  }
}
.heading-section.heading-section-white .subheading {
  color: rgba(255, 255, 255, 0.7);
}
.heading-section.heading-section-white h2 {
  color: #fff;
}
.heading-section.heading-section-white p {
  color: rgba(255, 255, 255, 0.9);
}

.video {
  width: 100%;
  position: relative;
}

.icon-video {
  width: 90px;
  height: 90px;
  background: #11a0d9;
  -webkit-box-shadow: 0px 0px 34px -8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 34px -8px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 34px -8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  margin: 0 auto;
  margin-bottom: -45px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.icon-video span {
  font-size: 30px;
  color: #fff;
}

.hotel-img,
.img,
.blog-img,
.user-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
