.hero-wrap {
  width: 100%;
  height: 100%;
  position: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
}
@media (max-width: 991.98px) {
  .hero-wrap {
    background-position: top center !important;
  }
}
.hero-wrap .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  opacity: 0.2;
  background: #000000;
}
.hero-wrap.hero-wrap-2 {
  height: 310px;
}
.hero-wrap.hero-wrap-2 .slider-text {
  height: 310px;
}
.hero-wrap.hero-wrap-2 .slider-text .bread {
  font-weight: 900;
  color: #fff;
  font-size: 50px;
}
.hero-wrap.hero-wrap-2 .slider-text .breadcrumbs {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
}
.hero-wrap.hero-wrap-2 .slider-text .breadcrumbs span {
  color: #fff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}
.hero-wrap.hero-wrap-2 .slider-text .breadcrumbs span a {
  color: #fff;
}
